<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Skill Occupation</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined :error="$v.skill_occupation.title.$error"
                  dense
                  v-model="skill_occupation.title">
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.title">**{{ errors.title[0] }}</span>

              <span class="text-danger" v-if="$v.skill_occupation.title.$error">This Title field is Required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined
                  dense :error="$v.skill_occupation.code.$error"
                  v-model="skill_occupation.code">
                <template v-slot:label>
                  Code <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.code">**{{ errors.code[0] }}</span>
              <span class="text-danger" v-if="$v.skill_occupation.code.$error">This Code field is Required</span>

            </v-col>

            <v-col cols="12" md="12">
              <v-autocomplete
                  v-model="skill_occupation.country_id"
                  :items="countries" filled
                  outlined :error="$v.skill_occupation.country_id.$error"
                  dense color="blue-grey lighten-2"
                  label="Select Country"
                  item-text="title"
                  item-value="id">

              </v-autocomplete>
              <span class="text-danger" v-if="errors.country_id">**{{ errors.country_id[0] }}</span>
              <span class="text-danger"
                    v-if="$v.skill_occupation.country_id.$error">This Country field is Required</span>

            </v-col>
            <v-col cols="12" sm="12" md="12">
              <label>
                <strong>Description</strong>
              </label>
              <ckeditor :config="editorConfig"
                        v-model="skill_occupation.description">
              </ckeditor>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              Status
              <v-switch
                  v-model="skill_occupation.is_active"
                  :label="skill_occupation.is_active ? 'Active' : 'InActive'"
              ></v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn v-if="checkIsAccessible('skill-occupation', 'edit') && checkIsAccessible('skill-occupation', 'create')"
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SkillOccupationService from "@/services/skill-occupation/SkillOccupationService";
import CountryService from "@/services/country/CountryService";

const country = new CountryService();
import {required, requiredIf} from "vuelidate/lib/validators";

const skillOccupation = new SkillOccupationService;
export default {
  validations: {
    skill_occupation: {
      title: {required},
      code: {required},
      country_id: {required},
    }
  },
  data() {
    return {
      title: '',
      dialog: false,
      edit: false,
      loading: false,
      errors: [],
      skill_occupation: {
        title: '',
        description: '',
        country_id: '',
        application_type: '',
        is_active: true,
      },
      types: [
        {value: 'study', text: 'Study'},
        {value: 'visit', text: 'Visit'},
        {value: 'work', text: 'Work'},
      ],
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
      countries: [],
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    openDialog() {

      this.dialog = true;
    },
    createSkillOccupation() {
      this.openDialog();
      this.resetForm();
      this.title = "Create";
      this.edit = false;
    },
    editSkillOccupation(item) {
      this.openDialog();
      this.edit = true;
      this.skill_occupation = item;
      this.title = "Edit";
    },
    resetForm() {
      this.skill_occupation = {
        title: '',
        description: '',
        country_id: '',
        application_type: '',
        is_active: true,
      };
      this.errors = [];
    },
    getCountries() {
      country
          .getAllCountry({'is_operating_country': 1})
          .then((response) => {

            this.countries = response.data.data;
          })
          .catch((error) => {
            // console.log(error);
          })
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      skillOccupation
          .create(this.skill_occupation)
          .then(response => {
            this.$snotify.success("Skill Occupation created successfully");
            this.closeDialog();
            this.resetForm();
            this.errors = [];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          }).finally(() => {
        this.loading = false
      })
    },
    update: function () {
      this.loading = true;
      skillOccupation
          .update(this.skill_occupation.id, this.skill_occupation)
          .then(response => {
            this.$snotify.success("Skill Occupation updated successfully");
            this.closeDialog();
            this.resetForm();
            this.errors = [];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          }).finally(() => {
        this.loading = false
      })

    },
  },
  mounted() {
    this.getCountries();
  }
}
</script>
